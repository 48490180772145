<template>
  <div>
    <div>
      <el-form>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="缴款人姓名">
              <el-input v-model="search.name" placeholder="请输入缴款人姓名">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="缴费状态">
              <el-select v-model="search.payState">
                <el-option label="未缴费" :value="0" />
                <el-option label="已缴费" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="退款状态">
              <el-select v-model="search.refundState">
                <el-option label="未退款" :value="0" />
                <el-option label="已退款" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="缴费时间">
              <el-date-picker
                v-model="time"
                type="datetimerange"
                range-separator="到"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="YYYY/MM/DD HH:mm:ss "
                value-format="YYYY-MM-DD HH:mm:ss"
              />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="退费日期">
              <el-date-picker
                v-model="refundTime"
                type="daterange"
                range-separator="到"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-button type="primary" @click="onDetail"> 搜索 </el-button>
            <el-button type="primary" @click="onAddAnOrder"> 添加 </el-button>
            <el-button type="primary" @click="templateDownload">
              模板下载
            </el-button>
            <el-button type="primary" @click="onExport"> 导出 </el-button>
            <el-button type="primary" @click="onImport"> 导入 </el-button>
          </el-col>
        </el-row>
      </el-form>

      <!-- 表格 -->
      <div class="table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="virtualId"
            label="序号"
            width="100"
            align="center"
          />
          <el-table-column prop="id" label="ID" />
          <el-table-column prop="name" label="姓名" />
          <el-table-column prop="number" label="缴费编号" />
          <el-table-column prop="created_time" label="创建时间" />
          <el-table-column prop="cardno" label="凭证号" />
          <el-table-column prop="amt" label="金额" />
          <el-table-column label="支付状态">
            <template v-slot="{ row }">
              {{ row.payState ? '已支付' : '未支付' }}
            </template>
          </el-table-column>
          <el-table-column prop="pay_time" label="支付时间">
            <template v-slot="{ row }">
              {{ row.pay_time || '暂无' }}
            </template>
          </el-table-column>
          <el-table-column label="退费状态">
            <template v-slot="{ row }">
              {{ row.refundState ? '已退费' : '未退费' }}
            </template>
          </el-table-column>
          <el-table-column prop="demo" label="退费原因" />
          <el-table-column prop="refund_time" label="退费时间" />
          <el-table-column label="操作" width="250">
            <template #default="{ row }">
              <el-button
                type="primary"
                plain
                size="small"
                @click="onDetailtBtn(row)"
              >
                详情</el-button
              >
              <el-button size="small" type="danger" @click="onDeletebtn(row)"
                >删除</el-button
              >
              <el-button
                plain
                size="small"
                type="success"
                @click="onRefund(row.id)"
                v-if="row.refundState === 0"
                >置为已退费</el-button
              >
              <el-button
                plain
                size="small"
                type="success"
                style="
                  border-color: rgb(172, 169, 169);
                  background-color: rgb(172, 169, 169);
                  color: white;
                "
                v-if="row.refundState === 1"
                >已退费</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-row style="margin-top: 20px">
          <el-col>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="search.total"
            >
            </el-pagination>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 详情 -->
    <payment-details
      v-model="detailShow"
      :moneyItem="moneyItem"
    ></payment-details>

    <!-- 添加订单 -->
    <add-an-order
      v-model="orderShow"
      :number="search.number"
      :onDetail="onDetail"
    ></add-an-order>
    <detail-wx-from
      v-model="detailVisibleWx"
      :orderid="id"
      :onDetail="onDetail"
    ></detail-wx-from>
  </div>
</template>

<script setup>
import paymentDetails from './paymentDetails.vue'
import addAnOrder from './addAnOrder.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  detailAPI,
  paymentDetailsAPI,
  payorderDelAPI,
  reportSocpayAPI
} from '@/api/social-payment'
import { useRoute, useRouter } from 'vue-router'
import { ref, watch, computed } from 'vue'
import detailWxFrom from './detailWxFrom.vue'

const route = useRoute()

const search = ref({
  page: 1, // 当前页
  limit: 10, // 每页显示条数
  total: 0,
  payState: '',
  refundState: '',
  payTimeStart: '',
  payTimeEnd: '',
  refundTimeStart: '',
  refundTimeEnd: '',
  number: route.params.number
})

const time = ref([])
watch(
  () => time.value,
  (val) => {
    if (val) {
      search.value.payTimeStart = val[0]
      search.value.payTimeEnd = val[1]
    } else {
      search.value.payTimeStart = ''
      search.value.payTimeEnd = ''
    }
  },
  {
    deep: true
  }
)

const refundTime = ref([])
watch(
  () => refundTime.value,
  (val) => {
    if (val) {
      search.value.refundTimeStart = val[0]
      search.value.refundTimeEnd = val[1]
    } else {
      search.value.refundTimeStart = ''
      search.value.refundTimeEnd = ''
    }
  },
  {
    deep: true
  }
)

const orderShow = ref(false)
const onAddAnOrder = () => {
  orderShow.value = true
}

// 模板下载
const templateDownload = () => {
  window.location.href =
    'http://lnjsxy.college.yslts.com/excel/社会缴费导入信息模板.xlsx'
}

// 导入
const router = useRouter()
const onImport = () => {
  router.push(`/finance/import/${search.value.number}`)
}

// 导出
const onExport = async () => {
  const data = await reportSocpayAPI(search.value)
  window.location.href = `http://lnjsxy.college.yslts.com/${data}`
}

/** 表格 S */
// 定义起始的虚拟序号
// const virtualIdStart = ref(1)

// 定义一个计算属性来计算虚拟序号
const virtualId = computed(() => {
  return (search.value.page - 1) * search.value.limit + 1
})
const tableData = ref([])
// 在更新表格数据时，为每一行添加虚拟序号
const updateTableData = () => {
  tableData.value.forEach((item, index) => {
    item.virtualId = virtualId.value + index
  })
}
// 更新虚拟序号的函数
// const updateVirtualId = () => {
//   tableData.value.forEach((item) => {
//     item.virtualId = virtualId.value // 使用相同的虚拟序号
//   })
// }
// 在 onDetail 函数中调用 updateVirtualId 函数，更新虚拟序号
const onDetail = async () => {
  const res = await paymentDetailsAPI({ id: route.params.id })
  search.value.number = res.number

  const data = await detailAPI(search.value)
  search.value.total = data.total
  tableData.value = data.data

  // 更新表格中的虚拟序号
  updateTableData()
}
onDetail()

const detailShow = ref(false)
// 编辑
const moneyItem = ref({})
const onDetailtBtn = (row) => {
  moneyItem.value = row
  detailShow.value = true
}

// 删除
const onDeletebtn = ({ id }) => {
  ElMessageBox.alert('是否删除该缴费', '提示', {
    confirmButtonText: '确认',
    callback: async () => {
      await payorderDelAPI({ id })
      onDetail()
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
    }
  })
}

// 退费
// const onRefund = async ({ id }) => {
//   await refundAPI({ id })
//   onDetail()
//   ElMessage({
//     type: 'success',
//     message: '置为已退费成功'
//   })
// }

// 置为已退费
// const onRefund = async ({ id }) => {
//   ElMessageBox.alert('是否将改订单置为退费', '退费', {
//     confirmButtonText: '确认',
//     callback: async (action) => {
//       if (action === 'cancel') {
//         return ElMessage.success('置为已退费已取消')
//       }
//       await wxPutAsARefundFeeAPI({ id })
//       onDetail()
//       ElMessage.success('置为已退费成功')
//     }
//   })
// }
// 退费
const detailVisibleWx = ref(false)
const id = ref('')
const onRefund = (orderid) => {
  console.log('orderid', orderid)
  console.log(detailVisibleWx.value)
  detailVisibleWx.value = true
  id.value = orderid
}
/** 表格 E */

/**
 *  分页 S
 */

// 处理页码变化
// const handlePageChange = (newPage) => {
//   search.value.page = newPage
//   onDetail()
// }
// 在翻页时调用 updateVirtualId 函数，更新虚拟序号
// handleCurrentChange: (val) => {
//   search.value.page = val
//   onDetail()

//   // 更新虚拟序号
//   updateVirtualId()
// }
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onDetail()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onDetail()
  // 更新虚拟序号
  // updateVirtualId()
}
/**
 *  分页 E
 */
</script>

<style lang="scss" scoped>
.table {
  margin-top: 20px;
}
</style>
